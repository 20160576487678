<template>
  <div>
    <Header></Header>
    <PanelMenu></PanelMenu>

    <div class="panel-page">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="panel-table" v-if="isLoading && userDetails == null">
              <div class="spinner-wrap">
                <b-spinner variant="light" class="mr-2" medium></b-spinner>
                <div class="text">Loading, please wait...</div>
              </div>
            </div>
            <div class="panel-table" v-if="!isLoading && userDetails != null">
              <div class="head">
                <h3>
                  Welcome, <strong>{{ userDetails.billing_address.fullname }}</strong>
                </h3>
                <div class="d-flex"></div>
              </div>
              <div class="content">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="panel-stat-box">
                          <div class="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-box-seam"
                              viewBox="0 0 16 16"
                            >
                              <path
                                d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5l2.404.961L10.404 2l-2.218-.887zm3.564 1.426L5.596 5 8 5.961 14.154 3.5l-2.404-.961zm3.25 1.7-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z"
                              />
                            </svg>
                          </div>
                          <div>
                            <span>Orders</span>
                            <strong>{{ total_order_records }}</strong>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="panel-stat-box">
                          <div class="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-box-seam"
                              viewBox="0 0 16 16"
                            >
                              <path
                                d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5l2.404.961L10.404 2l-2.218-.887zm3.564 1.426L5.596 5 8 5.961 14.154 3.5l-2.404-.961zm3.25 1.7-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z"
                              />
                            </svg>
                          </div>
                          <div>
                            <span>Addresses</span>
                            <strong>{{ total_address_records }}</strong>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="panel-stat-box">
                          <div class="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-box-seam"
                              viewBox="0 0 16 16"
                            >
                              <path
                                d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5l2.404.961L10.404 2l-2.218-.887zm3.564 1.426L5.596 5 8 5.961 14.154 3.5l-2.404-.961zm3.25 1.7-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z"
                              />
                            </svg>
                          </div>
                          <div>
                            <span>Current Balance</span>
                            <strong>{{ userDetails.balance | toCurrency }}</strong>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="panel-stat-box">
                          <div class="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-box-seam"
                              viewBox="0 0 16 16"
                            >
                              <path
                                d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5l2.404.961L10.404 2l-2.218-.887zm3.564 1.426L5.596 5 8 5.961 14.154 3.5l-2.404-.961zm3.25 1.7-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z"
                              />
                            </svg>
                          </div>
                          <div>
                            <span>Remaining Shipments</span>
                            <strong>{{ userDetails.plan_remaining_shipments }}</strong>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="dashboard-box">
                      <div class="title">Active Plan Details</div>
                      <ul v-if="userDetails.plan">
                        <li><b>Name: </b>{{ userDetails.plan.name }}</li>
                        <li><b>Price: </b>{{ userDetails.plan.price | toCurrency }}</li>
                        <li>
                          <b>Max Shipmets: </b> {{ userDetails.plan.max_shipments }}
                        </li>
                        <li>
                          <b>Remaining Shipmets: </b>
                          {{ userDetails.plan_remaining_shipments }}
                        </li>
                      </ul>
                      <p class="text-white py-3" v-else>You dont have any active plan!</p>
                    </div>
                  </div>
                </div>

                <div class="user-tx-list mt-3">
                  <h4 class="text-white">Your Transactions</h4>
                  <div class="panel-table mt-3">
                    <table>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th style="text-align: left">Description</th>
                          <th>Type</th>
                          <th>Amount</th>
                          <th>Created At</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(tx, i) in txData" :key="i">
                          <td>
                            <p>#{{ tx.id }}</p>
                          </td>
                          <td style="text-align: left">
                            <p>{{ interpolate(tx.description, tx.format) }}</p>
                          </td>
                          <td>
                            <p>{{ tx.type == 'purchase' ? 'Purchase' : tx.type == 'planpurchase' ? 'Plan Purchase' : '' }}</p>
                          </td>
                          <td>
                            <span>{{ tx.amount | toCurrency }}</span>
                          </td>
                          <td>
                            <p>{{ tx.created_at | formatDate }}</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import { BAlert, BSpinner } from "bootstrap-vue";
import axios from "axios";
import Header from "../../layouts/components/HeaderComp.vue";
import Footer from "../../layouts/components/FooterComp.vue";
import PanelMenu from "../../layouts/components/PanelMenu.vue";
import { uuid } from "vue-uuid";
export default {
  components: {
    BAlert,
    BSpinner,
    Header,
    Footer,
    PanelMenu,
  },
  data() {
    return {
      errors: [],
      isLoading: false,
      userDetails: null,
      total_order_records: 0,
      total_address_records: 0,

      txData: [],

      page: 1,
      limit: 10,
    };
  },
  mounted() {
    this.getUserDetails();
    this.getTotalOrders();
    this.getTotalAddresses();
    this.getTransactions();
  },
  methods: {
    getUserDetails() {
      this.isLoading = true;
      axios
        .get(`user`)
        .then((res) => {
          console.log(res.data);
          this.userDetails = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.errors.push(err);
        });
    },

    getTotalOrders() {
      axios
        .get(`purchases?page=1&limit=1`)
        .then((res) => {
          this.total_order_records = res.data.total_records;
        })
        .catch((err) => {
          this.errors.push(err);
        });
    },

    getTotalAddresses() {
      axios
        .get(`address?page=1&limit=1`)
        .then((res) => {
          this.total_address_records = res.data.total_records;
        })
        .catch((err) => {
          this.errors.push(err);
        });
    },

    getTransactions() {
      axios
        .get(`user/transactions?page=${this.page}&limit=${this.limit}&search=`)
        .then((res) => {
          console.log(res.data);
          this.txData = res.data.records;
        })
        .catch((err) => {
          this.errors.push(err);
        });
    },

    interpolate(string, arr) {
      let newString = string.replace('%s', '%%')
      newString = newString.replace('%t', '%%')
      newString = newString.replace('%s', '%%')
      arr.forEach((item, index) => (newString = newString.replace(`%%`, item)));
      return newString;
    },
  },
};
</script>

<style lang="scss" scoped>

table {
  thead {
    tr {
      th {
        font-size: 13px;
      }
    }
  }
  tbody {
    tr {
      td {
        span, p {
          font-size: 13px;
        }
      }
    }
  }
}

</style>
